/* GSAP ANIMATIONS */
gsap.registerPlugin(ScrollTrigger);
window.addEventListener('load', function () {
    gsap.from(".first-fold__container", {duration: 1, y: 100, opacity: 0});
})
const tlAbout = gsap.timeline();
tlAbout.from(".about--foto", {opacity: 0, duration: 0.8})
    .from(".carousel__wrapper", {opacity: 0, duration: 0.5})
    .from(".about--title", {y: -50, opacity: 0, duration: 0.4})
    .from(".carousel__redline", {x: -150, opacity: 0, duration: 0.3})
    .from(".about--txt", {y: -50, opacity: 0, duration: 0.3});
ScrollTrigger.create({
    animation: tlAbout,
    trigger: "#about",
    start: "top 50%",
});

const tlSector = gsap.timeline();
tlSector.from(".sectores__title", {opacity: 0, duration: 0.3, y: -50})
    .from(".sectores__txt", {opacity: 0, duration: 0.3, y: -50});
ScrollTrigger.create({
    animation: tlSector,
    trigger: ".sectores__title",
    start: "top 50%",
});
gsap.from(".sector", {
    scrollTrigger: {
        trigger: ".sector",
        start: "top 60%",
    },
    opacity: 0,
    x: -100,
    duration: 0.8,
    stagger: 0.1
});
const tlServicio = gsap.timeline();
tlServicio.from(".servicio__title", {opacity: 0, duration: 0.3, x: 100})
    .from(".btn-servicio", {opacity: 0, duration: 0.3, x: 100});
ScrollTrigger.create({
    animation: tlServicio,
    trigger: ".servicio",
    start: "top 50%",
});
const tlProveedor = gsap.timeline();
tlProveedor.from(".proveedores__title", {opacity: 0, duration: 0.3, y: -50})
    .from(".proveedores__txt", {opacity: 0, duration: 0.3, y: -50});
ScrollTrigger.create({
    animation: tlProveedor,
    trigger: ".proveedores__title",
    start: "top 50%"
});
gsap.from(".proveedor img", {
    scrollTrigger: {
        trigger: ".proveedor img",
        start: "center 60%"
    },
    opacity: 0,
    y: 100,
    duration: 0.8,
    stagger: 0.1
});
gsap.from(".forma", {
    scrollTrigger: {
        trigger: ".info",
        start: "center 60%"
    },
    opacity: 0,
    x: 100,
    duration: 1
});

/* MOBILE MENU FUNCTIONALITY */
const menuBtn = document.querySelectorAll('.menu--btn');
const menuOverlay = document.querySelector('.menu__overlay');
const menuItemLink = document.querySelectorAll('.menu__item--link');
const navMenuItem = document.querySelectorAll('.nav-menu-item');

function handleClickMenu() {
    menuOverlay.classList.toggle('open');
    document.querySelector('body').classList.toggle('stop--scrolling');
}


function handleClickMobileLink(event) {
    menuOverlay.classList.toggle('open');
    document.querySelector('body').classList.toggle('stop--scrolling');
    setTimeout(function(){
        let toSection = event.target.dataset.block;
        let y = document.getElementById(toSection).getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({top: y, behavior: 'smooth'});
    },650);
}

function handleClickNavLink(event) {
    let toSection = event.target.dataset.block;
    let y = document.getElementById(toSection).getBoundingClientRect().top + window.pageYOffset - 60;
    window.scrollTo({top: y, behavior: 'smooth'});
}

menuBtn.forEach((btn) => {
    btn.addEventListener('click', handleClickMenu);
});
menuItemLink.forEach((link) => {
    link.addEventListener('click', handleClickMobileLink);
});
navMenuItem.forEach((item) => {
    item.addEventListener('click', handleClickNavLink);
});

/* NAVBAR FIXED FUNCTIONALITY */
const header = document.querySelector('.header-container');
window.onscroll = function() {
    if (window.pageYOffset > 150) {
      header.classList.add('fixed');
    }else {
        header.classList.remove('fixed');
    }
};

/* ABOUT CAROUSEL FUNCTIONALITY */
const aboutTexts = {
    1: {
        title: "Somos Térmica Soluciones",
        txt: "Térmica Soluciones es una empresa especialista en la optimización energética dentro del sector térmico-industrial, brindando soluciones especializadas y orientadas a la sustentabilidad, ahorro de energía, reducción de costos y seguridad."
    },
    2: {
        title: "Especialistas en soluciones inteligentes",
        txt: "Proveer soluciones inteligenes a las necesidades de nuestros clientes en el área de ingeniería térmica (vapor, condensado, aire y agua caliente) a través de productos y servicios de calidad que permitan optimizar el consumo energético y cuidar el medio ambiente."
    },
    3: {
        title: "Buscamos ser líderes en el mercado",
        txt: "Ser una emrpesa líder y reconocida por la calidad y eficiencia de sus servicios, brindando buenas experiencias a nuestros clientes en sus proyectos y enfocándonos en el desarrollo sostenible."
    }
}


const dots = document.querySelectorAll('.dot');
const aboutTitle = document.querySelector('#aboutTitle');
const aboutTxt = document.querySelector('#aboutTxt');
const aboutFoto = document.querySelector('#aboutFoto');
const arrowLeft = document.querySelector('#arrowLeft');
const arrowRight = document.querySelector('#arrowRight');
const aboutImg = document.querySelectorAll('.about--img');
const aboutH2 = document.querySelectorAll('.about--h2');
const redLine = document.querySelector('.carousel__redline');
const aboutP = document.querySelectorAll('.about--p');
let aboutIndex = 1;
let dotsIndex = 0;
let aboutTimer = 0;
let currenFoto;
let newFoto;

function dotClicked(event){
    let index = event.target.dataset.txt;
    aboutIndex = index;
    redLine.style.transition = 'all 0.3s ease';
    redLine.style.opacity = 0;
    redLine.style.transform = 'translateX(-150px)';
    dots[dotsIndex].classList.remove('active');
    aboutImg[dotsIndex].classList.remove('opaque');
    aboutH2[dotsIndex].classList.remove('active');
    aboutP[dotsIndex].classList.remove('active');
    dotsIndex = index - 1;
    dots[dotsIndex].classList.add('active');
    aboutImg[dotsIndex].classList.add('opaque');
    setTimeout(function(){ 
        aboutH2[dotsIndex].classList.add('active');
        setTimeout(function(){ 
            redLine.style.transform = 'translateX(0px)';
            redLine.style.opacity = 1;
            setTimeout(function(){
                aboutP[dotsIndex].classList.add('active');
            }, 400);
        }, 300);
    }, 500);
}

function prevAbout() {
    dots[dotsIndex].classList.remove('active');
    aboutImg[dotsIndex].classList.remove('opaque');
    aboutH2[dotsIndex].classList.remove('active');
    aboutP[dotsIndex].classList.remove('active');
    redLine.style.transition = 'all 0.3s ease';
    redLine.style.opacity = 0;
    redLine.style.transform = 'translateX(-150px)';
    dotsIndex--;
    aboutIndex--;
    if(aboutIndex < 1) {
        aboutIndex = 3;
    }
    if(dotsIndex < 0) {
        dotsIndex = 2;
    }
    dots[dotsIndex].classList.add('active');
    aboutImg[dotsIndex].classList.add('opaque');
    setTimeout(function(){ 
        aboutH2[dotsIndex].classList.add('active');
        setTimeout(function(){ 
            redLine.style.transform = 'translateX(0px)';
            redLine.style.opacity = 1;
            setTimeout(function(){
                aboutP[dotsIndex].classList.add('active');
            }, 400);
        }, 300);
    }, 500);
}

function nextAbout() {
    dots[dotsIndex].classList.remove('active');
    aboutImg[dotsIndex].classList.remove('opaque');
    aboutH2[dotsIndex].classList.remove('active');
    aboutP[dotsIndex].classList.remove('active');
    redLine.style.transition = 'all 0.3s ease';
    redLine.style.opacity = 0;
    redLine.style.transform = 'translateX(-150px)';
    dotsIndex++;
    aboutIndex++;
    if(aboutIndex > 3) {
        aboutIndex = 1;
    }
    if(dotsIndex > 2) {
        dotsIndex = 0;
    }
    dots[dotsIndex].classList.add('active');
    aboutImg[dotsIndex].classList.add('opaque');
    setTimeout(function(){ 
        aboutH2[dotsIndex].classList.add('active');
        setTimeout(function(){ 
            redLine.style.transform = 'translateX(0px)';
            redLine.style.opacity = 1;
            setTimeout(function(){
                aboutP[dotsIndex].classList.add('active');
            }, 400);
        }, 300);
    }, 500);
}

function slideAbout() {
    dots[dotsIndex].classList.add('active');
    aboutImg[dotsIndex].classList.add('opaque');
    aboutH2[dotsIndex].classList.add('active');
    aboutP[dotsIndex].classList.add('active');
}

function playAbout() {
    aboutTimer = setInterval(nextAbout, 8000);
}

slideAbout();
//playAbout();


dots.forEach((dot) => {
    dot.addEventListener('click', dotClicked);
    dot.addEventListener('touchstart', dotClicked);
});

arrowLeft.addEventListener('click', prevAbout);
arrowRight.addEventListener('click', nextAbout);

/* TOUCH PORTION OF THE ABOUT */
var xDown = null;
var xDist = null;
var startTime = 0;
const validTime = 300;

function handleTouchStart(e) {
    let touchObj = e.changedTouches[0];
    xDown = touchObj.pageX;
    startTime = new Date().getTime();
    e.preventDefault();
}

function handleTouchEnd(e) {
    let touchObj = e.changedTouches[0];
    let timePassed = Math.abs(new Date().getTime() - startTime);
    xDist = touchObj.pageX - xDown;
    if (xDist > 150 && timePassed < validTime ) {
        prevAbout();
    }else if (xDist < -150 && timePassed < validTime){
        nextAbout();
    }
    e.preventDefault();
}



aboutTxt.addEventListener('touchstart', handleTouchStart);
aboutTxt.addEventListener('touchend', handleTouchEnd);

/* SERVICIOS FUNCTIONALITY */
const servicioBtn = document.querySelectorAll('.btn-servicio');
const iconoMas = './images/mas.png';
const iconoMenos = './images/menos.png';


function handleClickServices(event) {
    if(this.classList.contains('clicked')){
        let whichService = '.' + this.getAttribute('data-btn') + '-desc';
        document.querySelector(whichService).classList.remove('open');
        this.children[0].src = iconoMas;
        this.classList.remove('clicked');
    }else{
        let whichService = '.' + this.getAttribute('data-btn') + '-desc';
        document.querySelector(whichService).classList.add('open');
        this.children[0].src = iconoMenos;
        this.classList.add('clicked');
    }

}

servicioBtn.forEach((btn) => {
    btn.addEventListener('click', handleClickServices);
});

/* FORM FUNCTIONALITY  */
const inputs = document.querySelectorAll('.form__input');
const labels = document.querySelectorAll('.form__label');

function checkIn(event){
    labels.forEach((label) => {
        if(label.htmlFor == event.target.id) {
            label.classList.add('focused');
        }
    });
}

function checkOut(event){
    console.log(event.srcElement.value);
    if(event.srcElement.value === '') {
        labels.forEach((label) => {
            if(label.htmlFor == event.target.id) {
                label.classList.remove('focused');
            }
        });
    }else{
        return;
    }
}


inputs.forEach((input) => {
    input.addEventListener('focusin', checkIn);
});
inputs.forEach((input) => {
    input.addEventListener('focusout', checkOut);
});


